import React from "react";
import { Alert, Box, Button, TextField, MenuItem, Modal, Typography, Autocomplete, IconButton } from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import PasswordInput from "components/PasswordInput";
import { USER_TYPE } from "queries/users";
import CloseIcon from "@mui/icons-material/Close";
import { AxiosError } from "axios";

const UserMngmntInputType = {
  name: { label: "Username", inputType: "string", comp: TextField, optional: false },
  firstName: { label: "First Name", inputType: "string", comp: TextField, optional: true },
  lastName: { label: "Last Name", inputType: "string", comp: TextField, optional: true },
  email: { label: "User email", inputType: "email", comp: TextField, optional: false },
  password1: { label: "Password", inputType: "password", comp: PasswordInput, optional: false },
  password2: { label: "Password again", inputType: "password", comp: PasswordInput, optional: false },
  type: { label: "User Type", inputType: "select", comp: TextField, optional: false },
};

interface UserRegistrationModalProps {
  open: boolean;
  onClose: () => void;
  methods: any;
  onSubmit: (data: any) => void;
  corporations: any[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  setSearchTerm: (term: string) => void;
  registerError: AxiosError | null;
}

const UserRegistrationModal = ({ 
  open, 
  onClose, 
  methods, 
  onSubmit,
  corporations,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  setSearchTerm,
  registerError
}: UserRegistrationModalProps) => {
  const userRegistrationFontSize = {
    "& .MuiInputBase-input": { fontSize: 14 },
    "& .MuiFilledInput-root": { height: 45 },
    "& .MuiAutocomplete-input": { height: 13 },
  };

  const handleScroll = (event: React.SyntheticEvent) => {
    const list = event.currentTarget;
    if (
      list.scrollTop + list.clientHeight >= list.scrollHeight - 50 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="registration-modal"
    >
      <Box sx={{
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         bgcolor: 'background.paper',
         boxShadow: 24,
         p: 8,
         borderRadius: 8,
         minWidth: 400,
         maxWidth: 600,
      }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 2 }}>Add New User</Typography>
        {registerError !== null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {(() => {
              switch (registerError.response?.status) {
                case 500:
                  return "Server error, please try again later";
                default:
                  return "Registration error";
              }
            })()}
          </Alert>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
              {Object.entries(UserMngmntInputType).map(([k, v]) => {
                const fieldErrors = methods.formState.errors[k as keyof typeof UserMngmntInputType];
                return (
                  <Box key={k}>
                    <Controller
                      name={k}
                      render={({ field: { value, ...rest } }) => (
                        <v.comp
                          {...rest}
                          sx={userRegistrationFontSize}
                          required={!v.optional}
                          value={value || ""}
                          label={v.label}
                          variant="filled"
                          fullWidth
                          type={v.inputType}
                          select={k === 'type'}
                        >
                          {k === 'type' && Object.values(USER_TYPE).map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </v.comp>
                      )}
                    />
                    {fieldErrors && (
                      <Alert severity="error">
                        <span>{fieldErrors.message}</span>
                      </Alert>
                    )}
                  </Box>
                );
              })}
              <Controller
                name="corporationId"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={corporations.find(corp => corp.id === value) || null}
                    onChange={(_, newValue) => onChange(newValue?.id || null)}
                    onInputChange={(_, newValue) => setSearchTerm(newValue)}
                    options={corporations}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Corporation"
                        variant="filled"
                        sx={userRegistrationFontSize}
                      />
                    )}
                    ListboxProps={{
                      onScroll: handleScroll,
                      style: { maxHeight: 300 }
                    }}
                    loading={isFetchingNextPage}
                    size="small"
                  />
                )}
              />
              <Button variant="contained" type="submit">
                Register
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default UserRegistrationModal; 